"use client";

import Navbar from "@/components/navbar/Navbar";
import Footer from "@/components/shared/Footer";
import { useUser } from "@/contexts/user";
import Image from "next/image";
export default function NotFound() {
  const {
    user
  } = useUser();
  return <>
      <Navbar isLoggedIn={!!user} data-sentry-element="Navbar" data-sentry-source-file="not-found.tsx" />

      <div className="text-white px-5 flex items-center flex-col justify-center min-h-[calc(100vh-184px)]            ">
        <Image src="/assets/404.png" alt="not found" width={389} height={206} data-sentry-element="Image" data-sentry-source-file="not-found.tsx" />
        <h2 className="text-[30px] leading-10 sm:text-[42px] mt-4 sm:leading-[50px] font-semibold">
          Oops! Page Not Found
        </h2>
        <p className="text-lg text-center sm:text-[15px] mt-2.5 sm:leading-[22px] font-normal text-[#e0e0e0]">
          Looks like the page you&apos;re looking for has disappeared into the
          unknown.{" "}
        </p>
      </div>
      <Footer currentYear={new Date().getFullYear()} data-sentry-element="Footer" data-sentry-source-file="not-found.tsx" />
    </>;
}